<template>
  <v-row>
    <v-col :cols="12">
      <v-data-iterator
        :items="items"
        disable-sort
        disable-filtering
        disable-pagination
        :loading="loading"
        :search.sync="pagination.search"
        hide-default-footer
      >
        <template v-slot:header>
          <v-toolbar class="mb-1" v-if="showToolbar">
            <h5 class="text-h5 pl-5 align-center" v-if="title">
              {{ title }}
            </h5>
            <v-spacer></v-spacer>
            <v-col cols="2" v-if="purchaseExport">
              <purchase-export></purchase-export>
            </v-col>
            <v-col cols="2" v-if="saleExport">
              <sale-export></sale-export>
            </v-col>
            <v-col cols="3" v-if="userFilter">
              <user-filter></user-filter>
            </v-col>
            <v-col cols="6" v-if="purchaseFilter">
              <purchase-filter></purchase-filter>
            </v-col>
            <v-col cols="6" v-if="saleFilter">
              <sale-filter></sale-filter>
            </v-col>
            <v-col :cols="!$vuetify.breakpoint.mobile ? '3' : '8'">
              <v-text-field
                v-model="pagination.search"
                clearable
                flat
                @change="pagination.skip = 0"
                outlined
                dense
                hide-details
                class="mx-3"
                prepend-inner-icon="mdi-magnify"
                :label="$t('app.search')"
              ></v-text-field>
            </v-col>
            <v-btn
              link
              depressed
              color="primary"
              dark
              v-if="itemCreate && isAdmin()"
              :to="{ name: 'create-' + [routeName] }"
              >{{ $t("app.addNew") }}
              <v-icon right>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:no-data>
          <v-card class="pa-3 align-center d-flex justify-center flex-column" flat>
            <span class="pa-3">{{ $t("app.noData") }}</span>
          </v-card>
        </template>
        <template v-slot:loading>
          <v-card class="pa-3 align-center d-flex justify-center flex-column" flat>
            <span class="pa-3">{{ $t("app.loading") }}</span>
            <v-progress-linear indeterminate />
          </v-card>
        </template>
        <template v-slot:default="props">
          <v-row>
            <v-col v-for="item in props.items" :key="item.name" cols="12" sm="6" md="4" lg="3">
              <component
                v-bind:is="cardComponent"
                :item="item"
                :icon="icon"
                :show-actions="showActions"
                :namespace="namespace"
                @delete-item="deleteItem"
              ></component>
            </v-col>
          </v-row>
        </template>

        <template v-slot:footer>
          <v-btn
            v-if="!loading"
            :disabled="loading || disabled"
            dark
            outlined
            rounded
            shaped
            large
            class="teal accent-4 my-3 text-center mx-auto d-flex"
            @click="loadMore"
          >
            {{ $t("app.loadMore") }}<v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
      </v-data-iterator>
    </v-col>
  </v-row>
</template>
<script>
import components from "@/components/core/cards";
export default {
  components: {
    PurchaseExport: () => import("@/components/core/iterators/exports/PurchaseExport"),
    SaleExport: () => import("@/components/core/iterators/exports/SaleExport"),
    UserFilter: () => import("@/components/core/iterators/filters/UserFilter"),
    PurchaseFilter: () => import("@/components/core/iterators/filters/PurchaseFilter"),
    SaleFilter: () => import("@/components/core/iterators/filters/SaleFilter"),
    ...components
  },
  name: "NewsDataIterator",
  props: {
    routeName: {
      type: String,
      required: true
    },
    namespace: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: "Title"
    },
    icon: {
      type: String,
      required: false,
      default: "mdi-help"
    },
    showActions: {
      type: Boolean,
      required: false,
      default: false
    },
    cardComponent: {
      type: String,
      required: true,
      default: "NewsCard"
    },
    itemCreate: {
      type: Boolean,
      required: false,
      default: true
    },
    userFilter: {
      type: Boolean,
      required: false,
      default: false
    },
    purchaseFilter: {
      type: Boolean,
      required: false,
      default: false
    },
    purchaseExport: {
      type: Boolean,
      required: false,
      default: false
    },
    saleExport: {
      type: Boolean,
      required: false,
      default: false
    },
    saleFilter: {
      type: Boolean,
      required: false,
      default: false
    },
    showToolbar: {
      type: Boolean,
      required: false,
      default: true
    },
    storeAction: {
      type: String,
      required: false,
      default: "getItems"
    }
  },
  data() {
    return {};
  },
  computed: {
    loading: {
      get() {
        return this.$store.getters[this.namespace + "/loading"];
      }
    },
    disabled: {
      get() {
        return this.$store.getters[this.namespace + "/disabled"];
      }
    },
    items: {
      get() {
        return this.$store.getters[this.namespace + "/items"];
      },
      set() {
        this.$store.dispatch(this.namespace + "/" + this.storeAction, false);
      }
    },
    pagination: {
      get() {
        return this.$store.getters[this.namespace + "/pagination"];
      },
      set(value) {
        this.$store.dispatch(this.namespace + "/setPagination", value);
      }
    }
  },
  mounted() {
    Event.$on("reload-data", () => {
      this.getData();
    });
    this.getData();
  },
  watch: {
    pagination: {
      handler() {
        this.getData();
      },
      deep: true
    },
    $route: "getData"
  },
  methods: {
    loadMore() {
      this.pagination.skip = this.pagination.limit + this.pagination.skip;
    },
    deleteItem(id) {
      const _this = this;
      _this.$store.dispatch(_this.namespace + "/deleteItem", id).then(() => {
        this.getData();
      });
    },
    async getData() {
      await this.$store.dispatch(this.namespace + "/" + this.storeAction, false);
    }
  }
};
</script>
